.App {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  /* background-color: #eac717; */
  display: flex;
  /* justify-content: center; */
  justify-content: flex-end;
}

.content{
  flex:1; /*this is for sectioning the content area from the header, meaning the rest of the screen*/
  /* background-color: #eac717; */
}

.thisIsHeader {
  width: 100vw;
  height: 8vh;
  font-weight: 1000; 
  display: flex; 
}

.logo{
  /* background-color: #151502; */
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5vw;
}

.logo a{
  color: #151502;
  text-decoration: none;
  /* background-color: #4ae270; */
  white-space: nowrap;  /* Ensures the text doesn’t break into multiple lines */
  overflow: hidden;     /* Ensures content is clipped if it’s too large */
  text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
}

.thisIsHeader nav{
  /* background-color: #f6ec2bac; */
  height: 100%;
  width: 85%;
}

.thisIsHeader nav ul{
  /* background-color: #c6c652; */
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.thisIsHeader nav ul li{
  /* background-color: #d7a605; */
  height: 60%;
  display: flex;
  align-items: center;
  list-style: none;
  margin-inline: 1%;
  font-size: 1.7vw;
  /* font-size: 23px; */
}

@media (max-width:780px){
  .logo{
    /* background-color: #151502; */
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
  }
  .thisIsHeader nav ul li{
    /* background-color: #d7a605; */
    height: 60%;
    display: flex;
    align-items: center;
    list-style: none;
    margin-inline: 1%;
    font-size: 3vw;
    /* font-size: 23px; */
  }
}

.thisIsHeader nav ul li .nav-item{
  color: #151502;
  text-decoration: none;
}

nav ul li .nav-item:hover {
  /* font-size: larger; */
  color: #d7a605;
}

.nav-item.active {
  /* font-size: larger; */
  border-bottom: solid #4ae270;
}

/***************************Timeline Node css styles ******************************/
.timeNode{
  background-color: #c6c652;
  width: 100%;
  height: 100%;
}
  
/***************************home page css styles ***************************/

.home {
  /* background-color: #FCE19B; */
  display: grid;
  grid-template-rows: 1fr 5fr 1fr 4fr 6fr;
  height: 300dvh; /* Full viewport height */
}

@media only screen and (max-width: 780px){
  .home {
    /* background-color: #FCE19B; */
    display: grid;
    grid-template-rows: 1fr 3fr 0.2fr 4fr 3fr;
    height: 400vh; /* Full viewport height */
  }

  .Intro{
    /* background-color: #c6c652; */
    display: flex;
    align-items: center;
    padding-left: 10%;
    font-size: 3vw;
    /* font-size: 30px; */
    font-weight: 1000;
  }
  
  .Intro2 h1{
    height: 100%;
    width: 100%;
    /* background-color: #d7a605; */
    font-size: 9vw;
    /* font-size: 150px; */
    font-weight: 1000;
    display: flex;
    align-items: center;
    padding-left: 10%;
  }
  
  .Intro3{
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
  }
  
  .Intro3 h1{
    margin-top: 5%;
    font-size: 3vw;
    /* font-size: 50px; */
    font-weight: 1000;
  }
  
  .Intro4 {
    display: flex;
  }
  
  .Intro4_tools{
    flex:1;
    /* background-color: #dde24a; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
    padding-top: 3%;
  }
  
  .Intro4_tools h2{
    /* background-color: #151502; */
    height: 10vh;
  }
  
  .Intro4_tools img{
     height: 10vh;
     margin: 1%;
  }
  
  .Intro4_tools img:hover  {
    border: solid #4ae270;
  }
  
  .Intro4_skills{
    flex:3;
    display: flex;
    flex-direction: column;
    padding-top: 4%;
    padding-left: 3%;
  }
  
  .skill {
    /* background-color: #d7a605; */
    margin-bottom: 15px;
  }
  
  .skill-name {
    display: inline-block;
    width: 10dvw;
    font-weight: bold;
    font-size: 1.7vh;
    /* background-color: #4ae270; */
  }
  
  .skill-name:hover {
    color: greenyellow;
  }
  
  .skill-bar {
    display: inline-block;
    width: 45vw;
    /* width: 70%; */
    height: 1.5vh;
    /* height: 2.5vh; */
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    vertical-align: middle;
    /* margin-left: 2%; */
    margin-left: 6.5vw;
  }
    
}

.Intro{
  /* background-color: #c6c652; */
  display: flex;
  align-items: center;
  padding-left: 10%;
  font-size: 3vh;
  font-weight: 1000;
}

.Intro2 h1{
  height: 100%;
  width: 100%;
  /* background-color: #d7a605; */
  font-size: 9vh;
  /* font-size: 150px; */
  font-weight: 1000;
  display: flex;
  align-items: center;
  padding-left: 10%;
}

.Intro3{
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
}

.Intro3 h1{
  margin-top: 5%;
  font-size: 3vh;
  /* font-size: 50px; */
  font-weight: 1000;
}

.Intro4 {
  display: flex;
}

.Intro4_tools{
  flex:1;
  /* background-color: #dde24a; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding-top: 3%;
}

.Intro4_tools h2{
  /* background-color: #151502; */
  height: 10dvh;
}

.Intro4_tools img{
   height: 10dvh;
   margin: 1%;
}

.Intro4_tools img:hover  {
  border: solid #4ae270;
}

.Intro4_skills{
  flex:3;
  display: flex;
  flex-direction: column;
  padding-top: 4%;
  padding-left: 3%;
}

.skill {
  /* background-color: #d7a605; */
  margin-bottom: 15px;
}

.skill-name {
  display: inline-block;
  width: 10dvw;
  font-weight: bold;
  font-size: 1.7dvh;
  /* background-color: #4ae270; */
}

.skill-name:hover {
  color: greenyellow;
}

.skill-bar {
  display: inline-block;
  width: 45dvw;
  /* width: 70%; */
  height: 1.5dvh;
  /* height: 2.5vh; */
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  vertical-align: middle;
  /* margin-left: 2%; */
  margin-left: 9vw;
}


.skill-level {
  display: block;
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
}

.Intro5{
  display: flex; 
}

.Intro5_image{
  flex: 1;
  background-image: url("./assets/myself.jpg");
  background-attachment: fixed;
  background-color: #4ae270;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 780px) {
  .Intro5{
    display: grid;  
    grid-template-rows: 1fr 2fr;
  }

  .Intro5_image {
    background-image: url("./assets/myself.jpg");
    background-attachment:fixed;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center bottom; */
  }
  .Intro5_text{
    /* background-color: #4caf50; */
    /* display: flex; */
  }
  .about_me{
    /* background-color: #d7a605; */
    width: 100%;
    margin: 13%;
  }
  
  .about_me h1{
    font-weight: 1000;
    font-size: 1.8vh;
  }
  .about_me p{
    font-weight: bold;
    /* font-size: 20px; */
    font-size: 1.25dvw;
    /* font-size: 1.7vh; */
  }
  .about_me ul li{
    font-weight: bold;
    list-style: none;
    /* font-size: 20px; */
    font-size: 1.25dvw;
    /* font-size: 1.7vh; */
  }
  
  .about_me ul li:hover{
    color: #4ae270;
  }
  
  .about_me ul li + p{
    border-bottom: 2px solid #4ae270; /* Default border color */
    background-color: #e0e0e0;
  }
}

.Intro5_text{
  flex:3; 
  /* background-color: #4caf50; */
  display: flex;
}
.about_me{
  /* background-color: #d7a605; */
  width: 100%;
  margin: 13%;
}

.about_me h1{
  font-weight: 1000;
  /* font-size: 2.5dvw; */
  font-size: 4.5vh;
}
.about_me p{
  font-weight: bold;
  /* font-size: 20px; */
  /* font-size: 1.25dvw; */
  font-size: 1.9vh;
}
.about_me ul li{
  font-weight: bold;
  list-style: none;
  /* font-size: 20px; */
  /* font-size: 1.25dvw; */
  font-size: 1.9vh;
}

.about_me ul li:hover{
  color: #4ae270;
}

.about_me ul li + p{
  border-bottom: 2px solid #4ae270; /* Default border color */
  background-color: #e0e0e0;
}

.education{
  background-color: #5e4f20;
  width: 100%;
  padding-left: 15%;
}


.sec1 {
  /* background-color: #161510; Example background */
  display: flex; 
  flex-direction: row; 
  /* justify-content: center;  */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.sec2 {
  /* background-color: lightcoral; Example background */
  display: flex; 
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.c {
  margin: 1%; /* Add horizontal margin to create spacing between TimeNodes */
  flex-shrink: 0; 
}

.home_blog {
  flex: 1; /* this is for horizontal placing for blog and list section*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d7a605;
  /* margin-left: 5%; */
  border-right: solid;
  border-bottom: solid;
  border-width: 0.5vw;
}

.home_blog h1 {
  color: #151502;
  font-weight: 1000;
}

.home_middle {
  flex: 4; /* this is for horizontal placing for blog and list section*/
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid;
  border-width: 0.5vw;
}

.card:hover {
  transform: scale(1.1); /* Scale up when hovered */
}

.sec3 {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 2.5fr 1fr;
  grid-template-areas: /* Define named areas for elements */
    "home_about home_last"
    "about_logo about_button"; /* Define a new area for the button */
  user-select: none;
  /* background-color: rebeccapurple; */
}

@media only screen and (max-width:768px) {
  .sec3 {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 3.3fr 1fr;
    grid-template-areas: /* Define named areas for elements */
      "home_about home_last"
      "about_logo about_button"; /* Define a new area for the button */
    user-select: none;
    /* background-color: rebeccapurple; */
  }
}

.home_last {
  grid-area: home_last;
  border-left: solid;
  border-width: 0.5vh;
  margin-left: 5%;
  /* margin: 1.2%; */
  /* background-color: rgb(255, 255, 255); */
}

.home_about {
  grid-area: home_about;
  /* margin: 5%; */
  /* padding: 5%; */
  margin-left: 5%;
  /* background-color: aqua; */
}

.home_about ul {
  list-style: none;
  padding: 0;
  /* width: 100%;
  height: 100%; */
  /* background-color: #d7a605; */
}


.home_about ul li h2 {
  margin-bottom: 10%;
  font-weight: 1000;
}

.home_about ul li h2 .active{
  font-size: 3.5vh;
}

@media only screen and (max-width:768px){
  .home_about ul li h2 {
    margin-bottom: 10%;
    font-weight: 1000;
    font-size: 4vw;
  }
}

.about_logo {
  grid-area: about_logo;
  /* margin: 5%; */
  padding: 5%;
  /* padding-bottom: 10%; */
}

.about_logo h1 {
  font-size: 8vh;
  font-weight: 1000;
}

@media only screen and (max-width:768px){
  .about_logo h1 {
    font-size: 7vw;
    font-weight: 1000;
  }
}

.home_about ul .active h2{
  border-bottom: solid;
  border-width: 0.5vh; 
  font-size: 3.5vh;
}

@media only screen and (max-width:768px) {
  .home_about ul .active h2{
    border-bottom: solid;
    border-width: 0.5vh; 
    font-size: 4.5vw;
  }
}
.home_about ul h2{
  font-size: 3vh;
}
@media only screen and (max-width:768px) {
  .home_about ul h2{
    font-size: 4vw;
  }
}

.about_button {
  grid-area: about_button;
  /* margin: 1.2%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
  /* background-color: aquamarine; */
}

@media only screen and (max-width: 768px) {
  .about_button {
    grid-area: about_button;
    /* margin: 1.2%; */
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-right: 5%;
    /* background-color: aquamarine; */
  }
}


.about_button button {
  font-size: 3vh;
  font-weight: 1000;
  height: 8vh;
  width: 18vh;
  border-radius: 50px;
  border: 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Adjust shadow values as needed */
}

@media only screen and (max-width: 768px) {
  .about_button button {
    font-size: 4.5vw;
    font-weight: 1000;
    height: 12vw;
    width: 22vw;
    border-radius: 50px;
    border: 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* Adjust shadow values as needed */
  }
}

.about_button button:active {
  transform: translateY(3px) translateX(2px);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.about_description {
  /* background-color: rebeccapurple; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  padding: 1.2%;
}

@media only screen and (max-width:768px) {
  .about_description {
    /* background-color: rebeccapurple; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    padding: 1.2%;
  }
}

.about_description h1 {
  padding-left: 5%;
  font-weight: 1000;
  font-size: 3.5vh;
  color: #202539;
}

@media only screen and (max-width:768px) {
  .about_description h1 {
    padding-left: 5%;
    font-weight: 1000;
    font-size: 4.5vw;
    color: #202539;
    padding-right: 5%;
  }
}
.about_description ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  padding: 0;
}

.about_description ul li {
  list-style: none;
  font-size: 3.5vh;
  font-weight: 1000;
  padding-left: 5%;
}

@media only screen and (max-width:768px){
  .about_description ul li {
    list-style: none;
    font-size: 4.5vw;
    font-weight: 1000;
    padding-left: 5%;
    padding-right: 5%;
  }
}

/* .home_about h1 {
    color: #202539;
    font-weight: 1000;
  }
  
  .about_content h1 {
    padding: 5%;
    font-weight: 618;
  
  }
  
  .about_content h2 {
    padding: 5%;
    font-weight: 618;
  }
  
  .about_content ul li {
    padding: 1%;
    font-weight: 618;
  } */

/***************Projects page css styles**************************************************************/

.projects {
  flex: 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2%;
  margin: 1%;
}

.project_card {
  justify-self: center;
  align-self: center;
  padding: 3%;
}

.projects .custom-modal-container {
  height: 100vh;
  width: 100vw;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.item {
  border-bottom: 0.5vw solid black;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0fr;
  grid-template-areas: /* Define named areas for elements */
    "text showcase"
    " button button"; /* Define a new area for the button */
  user-select: none;
}
.text {
  margin-top: 5%;
  margin-left: 5%;
  padding: 5%;
  border-left: 0.5vw solid black; /* Corrected 'solide' to 'solid' */

  /* background-color: rebeccapurple; */
}
.text .title {
  /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
  font-size: 3vh;
  font-weight: 618;
  padding-top: 1%;
}

.text .bigTitle {
  /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
  font-size: 3vh;
  font-weight: 1000;
}
.text .exp {
  font-weight: 382;
}
.item .showcase {
  grid-area: showcase;
  margin-top: 5%;
  margin-right: 5%;
}
.item .showcase img {
  width: 100%;
  height: 100%;
}
.demo-button {
  grid-area: button; /* Assign the button to its named area */
  place-self: center;
  padding: 1%;
}

@media only screen and (max-width: 768px) {
  
.projects {
  flex: 3;
  /* background-color: #fffffb; */
  /* background-color: bisque; */
  /* min-height: 100px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2%;
  margin: 1%;
  /* background-image: url("./assets/dodge.jpg");
    background-size: cover;
    background-repeat: no-repeat; */
}

.project_card {
  justify-self: center;
  align-self: center;
  padding: 3%;
}

.projects .custom-modal-container {
  height: 100vh;
  width: 100vw;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.item {
  border-bottom: 0.5vw solid black;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 0fr;
  grid-template-areas: /* Define named areas for elements */
    "text showcase"
    " button button"; /* Define a new area for the button */
  user-select: none;
}
.text {
  margin-top: 5%;
  margin-left: 5%;
  padding: 5%;
  border-left: 0.5vw solid black; /* Corrected 'solide' to 'solid' */

  /* background-color: rebeccapurple; */
}

  .text .title {
    /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
    font-size: 4.5vw;
    font-weight: 618;
    padding-top: 3%;
  }
  
  .text .bigTitle {
    /* font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif; */
    font-size: 5vw;
    font-weight: 1000;
  }
  .text .exp {
    font-size: 3vw;
    font-weight: 382;
  }
  .item .showcase {
    grid-area: showcase;
    margin-top: 5%;
    margin-right: 5%;
  }
  .item .showcase img {
    width: 100%;
    height: 100%;
  }
  .demo-button {
    grid-area: button; /* Assign the button to its named area */
    place-self: center;
    padding: 1%;
  }
}

/**************************************************************************************************************/

